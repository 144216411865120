<template>
    <div class="wrapper">
        <div class="casebanner ">
            <div class="swiper" style="overflow: hidden;" id="caseSwiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in bannerCasesList" :key="index">
                        <div class="bannerCaseItem flex bg-white text-left" >
                           <div class="leftImage">
							   <el-image :src="item.image" fit="contain"></el-image>
						   </div>
						   <div class="rightContent">
							   <div >
								<div class="base-font-26 margin-bottom-20 ellipsis2">{{item.title}}</div>
							   </div>
							   <div>
								   <div><span class="text-bold">文章出处：</span>{{item.source}}</div>
								   <div class="padding-top-10"><span class="text-bold">发表时间：</span>{{item.showTime}}</div>
							   </div>
							   <div style="height:90px;line-height:2.1;">
								   <div class="base-font-12 text-888 margin-top-30 ellipsis3">
								   								   {{item.depict}}
								   </div>
							   </div>
							   <div style="margin-top:40px;">
								   <!-- <el-button icon="" type="success" plain round>成功按钮</el-button> -->
								   <div class="text-center pointer justify-center ljxqBtn flex align-center" @click="goDetails(item.id)"><i class="el-icon-right base-font-18"></i>了解详情</div>
							   </div>
						   </div>
                        </div>
                        
                    </div>
                </div>
                <!-- 如果需要导航按钮 -->
                <div class="swiper-button-prev caseSwiperBtn"></div>
                <div class="swiper-button-next caseSwiperBtn"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Swiper from 'swiper/swiper-bundle'
export default {
    props: {
        bannerCasesList: {
            type: Array,
			
        }
    },
    data() {
        return {
            bannerCurrent: 0,
            centerDialogVisible: false,
          

        }
    },
    mounted() {
        this.initSwiper()
    },
    methods: {
        initSwiper() {
            let _self = this
            new Swiper('#caseSwiper', {
                // loop: true, // 循环模式选项
                // loopAdditionalSlides: 1,
                // autoplay: true,
                autoplay: {
                    disableOnTnteraction: false,
                    delay: 3000,
                },
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    slideChangeTransitionStart: function () {
                        _self.bannerCurrent = this.activeIndex
                        // console.log(_self.bannerCurrent)
                        // console.log(this.activeIndex);
                    },
                    transitionEnd: function () {
                        // console.log('过渡结束');
                    },
                    init: function (swiper) {
                        //Swiper初始化了
                        // console.log('当前的slide序号是' + this.activeIndex);//或者swiper.activeIndex，swiper与this都可指代当前swiper实例
                        this.emit('transitionEnd');//在初始化时触发一次transitionEnd事件，需要先设置transitionEnd

                    },
                }
            })
        },
		goDetails(id){
			this.$emit('goDetails',id)
		}
    }

}
</script>
<style>
.casebanner .swiper .swiper-slide .bannerCaseItem {
    width: 100%;
    height: 230px;
    position: relative;
}
.bannerCaseItem .leftImage{width:340px;height:222px;}
.bannerCaseItem .leftImage .el-image{width:340px;height:222px;}
.bannerCaseItem .rightContent{
	padding:20px;box-sizing:border-box;
} 
.ljxqBtn{height:46px;width:146px;border-radius:24px;line-height:48px;border:2px solid #e6712c}
.ljxqBtn:hover{color:#ffffff;background:#e6712c;}
.swiper-button-prev.caseSwiperBtn{}
.caseSwiperBtn{width:70px;height:70px;border-radius:50%;background:rgba(0, 0, 0, 0.6);outline: none;}
.swiper-button-prev:after, .swiper-button-next:after{}
.caseSwiperBtn:after{font-size:30px;color:#ffffff}
</style>
