<template>
	<div class="baseContent">
		<div class="wrapper">
			<Breadcrumb :crumbsList="pathList"></Breadcrumb>
		</div>
		<div style="padding:20px 0;background:#f5f7fb;">
			<div class="base-font-40 padding-tb-20 text-center">{{curMenu.name}}</div>
			<div class="margin-top-20 wrapper">
				<CaseSwiper :bannerCasesList="bannerCasesList" @goDetails="goNewsDetails"></CaseSwiper>
			</div>
		</div>
		<div class="margin-top-20">
			<div class="wrapper">
				<div>
					<el-row :gutter="15">
						<el-col :span="12" v-for="(item,index) in list" :key="index">
							<div class="caseItemBox text-left bg-white" @click="goNewsDetails(item.id)">
								<div class="caseImage">
									<el-image :src="item.image" fit="cover"></el-image>
								</div>
								<div class="itemContent">
								 <div class="base-font-28 text-bold ellipsis2">{{item.title}}</div>
								<div class="base-font-22 text-666 margin-top-15"><span style="float: right">{{item.showTime}}</span></div>
									<!-- <div class="text-center pointer justify-center margin-top-10 flex align-center text-orange"><i class="el-icon-right base-font-16"></i>了解详情</div> -->
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
        <div v-if="hasNextPage" style="text-align: center;">
          <div @click="more" class="text-center pointer moreBtn align-center">加载更多</div>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
	import Breadcrumb from '../../components/breadcrumbs.vue'
	import CaseSwiper from './caseSwiper.vue'
  import {getWzContentPage, menuByUrl} from "@/api/api";
	export default{
		components: {
			Breadcrumb,CaseSwiper
		},
		data(){
			return{
        leftMenu: {childList: []},
        pathList: [],
        curMenu: {id: ''},
        pageNum: 1,
        pageSize: 10,
        hasNextPage: false,
        list: [],
        kindId: '',
				bannerCasesList:[]
			}
		},
    mounted() {
      var kindId = this.$route.query.kindId;
      var path = this.$route.path + "?kindId=" + kindId;
      this.kindId = kindId;
      console.log("path:" + path);
      this.menuByUrl(path);
      this.getWzContentPage();
    },
		methods:{
      menuByUrl(path) {
        menuByUrl(1, path).then(res => {
          this.pathList = res.data.pathList;
          this.leftMenu = res.data.leftMenu;
          this.curMenu = res.data.curMenu;
        })
      },
      more(){
        this.pageNum=this.pageNum+1;
        this.getWzContentPage();
      },
      getWzContentPage() {
        getWzContentPage({pageNum: this.pageNum, pageSize: this.pageSize, kindId: this.kindId}).then(res => {
          this.hasNextPage = res.hasNextPage;
          if(this.pageNum==1){
            this.list=[];
          }
          res.data.forEach((obj, index) => {
            obj.showTime = this.$moment(obj.clickTime).format('YYYY-MM-DD');
            if (this.pageNum == 1) {
              if (index < 3) {
                this.bannerCasesList.push(obj);
              } else {
                this.list.push(obj);
              }
            } else {
              this.list.push(obj);
            }
          })
        })
      },
      goNewsDetails(id) {
        this.$router.push({path: '/neswDetails?menuId=' + this.curMenu.id + '&id=' + id});
      },
			
		}
	}
</script>

<style>
.moreBtn{height:46px;width:146px;border-radius:24px;line-height:48px;border:2px solid #e6712c;margin: 0px auto;}
.moreBtn:hover{color:#ffffff;background:#e6712c;}
	.caseItemBox{width:100%;height:356px;margin-bottom: 30px;cursor: pointer;}
	.caseItemBox .caseImage{widht:100%;height:204px;overflow: hidden;}
	.caseItemBox .caseImage .el-image{
		width:100%;height:204px;
		transition: transform 0.7s;
		-webkit-transition: transform 0.7s;
		}
	.caseItemBox .itemContent {
		border-bottom: 1px solid #f0f0f0;
		border-left: 1px solid #f0f0f0;
		border-right: 1px solid #f0f0f0;
		padding: 20px;
		box-sizing: border-box;
		line-height: 1.5;
		height: 165px;
	}
	.caseItemBox:hover {
		box-shadow: 0 0 30px #dddddd;
	}
	
	.caseItemBox:hover .caseImage .el-image{
		 transform:scale(1.5);
		-webkit-transform:scale(1.5);
		-ms-transform:scale(1.5);		
		-moz-transform:scale(1.5);		
		-o-transform:scale(1.5);
		
		transition: transform 0.7s;
		-webkit-transition: transform 0.7s;
	}
	
	.viewCaseBtn{height:36px;width:110px;border-radius:19px;line-height:36px;border:1px solid #e6712c}
	.viewCaseBtn:hover{color:#ffffff;background:#e6712c;}
</style>